import YellowAvatar from "icons/yellow-avatar.svg"
import OrangeAvatar from "icons/orange-avatar.svg"
import LimeAvatar from "icons/lime-avatar.svg"
import GrayAvatar from "icons/gray-avatar.svg"
import VioletAvatar from "icons/violet-avatar.svg"
import GreenAvatar from "icons/green-avatar.svg"
import PinkAvatar from "icons/pink-avatar.svg"
import BlueAvatar from "icons/blue-avatar.svg"
import RepvueAvatar from "icons/repvue-avatar.svg"

export const getAvatar = (color, size, className) => {
  const AVATAR_DICTIONARY = {
    pink: () => (
      <PinkAvatar
        height={size}
        width={size}
        alt="pink-avatar"
        className={className}
      />
    ),
    orange: () => (
      <OrangeAvatar
        height={size}
        width={size}
        alt="orange-avatar"
        className={className}
      />
    ),
    yellow: () => (
      <YellowAvatar
        height={size}
        width={size}
        alt="yellow-avatar"
        className={className}
      />
    ),
    lime: () => (
      <LimeAvatar
        height={size}
        width={size}
        alt="lime-avatar"
        className={className}
      />
    ),
    gray: () => (
      <GrayAvatar
        height={size}
        width={size}
        alt="gray-avatar"
        className={className}
      />
    ),
    violet: () => (
      <VioletAvatar
        height={size}
        width={size}
        alt="violet-avatar"
        className={className}
      />
    ),
    green: () => (
      <GreenAvatar
        height={size}
        width={size}
        alt="green-avatar"
        className={className}
      />
    ),
    blue: () => (
      <BlueAvatar
        height={size}
        width={size}
        alt="blue-avatar"
        className={className}
      />
    ),
    repvue: () => (
      <RepvueAvatar
        height={size}
        width={size}
        alt="repvue-avatar"
        className={className}
      />
    ),
  }
  return AVATAR_DICTIONARY[color]()
}
