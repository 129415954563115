import { useDispatch } from "react-redux"
import { signOut } from "api/auth"
import pluralize from "pluralize"
import { trackAction } from "utils/analytics"
import { CLICKED } from "constants/track-actions"
import ProgressBar from "react-bootstrap/ProgressBar"
import Link from "next/link"
import BetaTag from "components/shared/BetaTag"
import Button from "components/shared/Button"
import Logout from "icons/logout.svg"

import styles from "./NavbarPopover.module.scss"

const NavbarPopover = ({ days_until_next_rating, daysCountdown }) => {
  const dispatch = useDispatch()

  const displayDays =
    days_until_next_rating < 300 ? days_until_next_rating : "Unlimited"

  return (
    <div className={styles.popover}>
      <div className={styles.popover__container}>
        <div className={styles.popover__menu}>
          <ul onClick={() => document.body.click()}>
            <li>
              <Link href="/user/dashboard">My Dashboard</Link>
            </li>
            <li>
              <Link href="/user/account-settings">Account Settings</Link>
            </li>
            <li>
              <Link href="/user/applications">Applications</Link>
            </li>
            <li>
              <Link href="/user/notifications">Notifications</Link>
            </li>
            <li>
              <span className={styles.popover__menu__item}>
                <Link href="/user/candidate-profile">Candidate Profile</Link>
                <BetaTag />
              </span>
            </li>
            <li>
              <Link href="/user/watchlist">Watchlist</Link>
            </li>
          </ul>
        </div>
        <div className={styles.popover__section}>
          <div className={styles.popover__days}>{`${displayDays} ${pluralize(
            "days",
            days_until_next_rating,
            false
          )} left`}</div>
          <div className={styles.popover__due}>
            until your next rating is due
          </div>
          <ProgressBar className={styles.popover__bar} now={daysCountdown} />
        </div>
        <div className={styles.popover__section}>
          <Button
            variant="outline-dark"
            size="medium"
            className={styles.popover__button}
            onClick={() => {
              trackAction(CLICKED, {
                location: "Navbar",
                click_text: "Sign Out",
                click_url: null,
                modal_name: null,
              })
              dispatch(signOut())
            }}
          >
            Sign Out{" "}
            <Logout height={20} width={20} className={styles.popover__logout} />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NavbarPopover
