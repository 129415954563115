import binder from "classnames/bind"
import classNames from "classnames"

import styles from "./Card.module.scss"

let cx = binder.bind(styles)

const Card = ({ children, offcanvas, styles }) => {
  let className = cx({
    content: true,
    offcanvas: offcanvas,
  })

  return <div className={classNames(className, styles)}>{children}</div>
}

export default Card
